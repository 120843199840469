import { useContext, useState, useEffect } from 'react';
import PropTypes                           from 'prop-types';

import { ApiContext } from '../context/Api';

const useClient = (collection, params, deps, shouldRun, type) => {
  if (!deps) {
    deps = [];
  }
  if (!type) {
    type = 'getItems'
  }
  const { client } = useContext(ApiContext);
  const [request, setRequest] = useState({ loading: false, meta: null, data: null, error: null });

  useEffect(() => {
    // TODO abortcontroller
    // const abortController = new AbortController();
    const fetchData = async () => {
      setRequest({ loading: true });
      let data;
      try {
        if (type === 'getItems') {
          data = await client.getItems(collection, params);
        } else if (type === 'getCollections') {
          data = await client.getCollections(params);
        }
        setRequest({
          loading: false,
          meta: data.meta ? data.meta : null,
          data: data.data
        })
      } catch (e) {
        setRequest({
          loading: false,
          error: e,
        })
      }
    };

    if (shouldRun) {
      fetchData();
    }
    return () => {
      // abortController.abort();
    }
    //eslint-disable-next-line
  }, [shouldRun, ...deps]);

  const { error, meta, data, loading } = request;
  return {
    error,
    meta,
    data,
    loading
  }
};

useClient.propTypes = {
  collection: PropTypes.string,
  params: PropTypes.object,
  deps: PropTypes.array,
  type: PropTypes.oneOf(['getItems', 'getCollections']),
  shouldRun: PropTypes.bool,
};

useClient.defaultProps = {
  type: 'getItems',
  shouldRun: true,
};

export default useClient;
